import { lazy } from "react";
import Diversity3Icon from '@mui/icons-material/Diversity3';

// route
export const consignmentRoute = { path: "/consignment", name: "menu.consignment", icon: <Diversity3Icon /> };
export const consignmentCreateRoute = { path: `${consignmentRoute.path}/add`, name: "consignment.form" };
export const consignmentEditRoute = { path: `${consignmentRoute.path}/edit`, name: "consignment.form" };
export const consignmentViewRoute = { path: `${consignmentRoute.path}/view`, name: "consignment.form" };

// component
const Consignment = lazy(() => import("../page/consignment/Consignment"));
const ConsignmentList = lazy(() => import("../page/consignment/list/ConsignmentList"));
const ConsignmentForm = lazy(() => import("../page/consignment/form/ConsignmentForm"));

export const consignmentRouteList = [consignmentRoute, consignmentCreateRoute, consignmentEditRoute, consignmentViewRoute]

export const consignmentRoutes = {
    element: <Consignment />,
    children: [
        { ...consignmentRoute, element: <ConsignmentList /> },
        { ...consignmentCreateRoute, element: <ConsignmentForm /> },
        { ...consignmentEditRoute, element: <ConsignmentForm /> },
        { ...consignmentViewRoute, element: <ConsignmentForm /> },
    ]
}