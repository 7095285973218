import { lazy } from "react";

// route //////////////
export const masterRoute = { path: "/setting", name: "menu.master", icon: "settings" };
export const mBankAccRoute = { path: `${masterRoute.path}/bankAccount`, name: "daftar Rekening" };
export const mCategoryRoute = { path: `${masterRoute.path}/category`, name: "common.category" };
export const mCourierRoute = { path: `${masterRoute.path}/courier`, name: "common.courier" };
export const mSalesRoute = { path: `${masterRoute.path}/sales`, name: "common.sales" };
export const mCustomerRoute = { path: `${masterRoute.path}/customer`, name: "common.customer" };
export const mSupplierRoute = { path: `${masterRoute.path}/supplier`, name: "common.supplier" };
export const mConvertionRoute = { path: `${masterRoute.path}/conversion`, name: "Konversi Barang" };
export const mFinanceRoute = { path: `${masterRoute.path}/fiance`, name: "master.finance" };
export const mPaymentMethodRoute = { path: `${masterRoute.path}/paymentMethod`, name: "Metode Pembayaran" };
export const mPOFeeRoute = { path: `${masterRoute.path}/poFee`, name: "PO Biaya Lainnya" };
export const mStorageRoomRoute = { path: `${masterRoute.path}/storageRoom`, name: "Ruang Penyimpanan" };
export const mTNCRoute = { path: `${masterRoute.path}/termAndCondition`, name: `master.tnc.` };
export const mUnitRoute = { path: `${masterRoute.path}/unit`, name: "common.unit" };

// warehouse
export const mWarehouseRoute = { path: `${masterRoute.path}/warehouse`, name: "master.whs_setting." };
export const mWarehouseAddressCreateRoute = { path: `${mWarehouseRoute.path}/create`, name: "master.whs_setting.add" };
export const mWarehouseAddressEditRoute = { path: `${mWarehouseRoute.path}/edit`, name: "master.whs_setting.edit" };

// user
export const mUserRoute = { path: `${masterRoute.path}/user`, name: "master.user." };
export const mUserCreateRoute = { path: `${mUserRoute.path}/create`, name: "master.user.add" };
export const mUserEditRoute = { path: `${mUserRoute.path}/edit`, name: "master.user.edit" };

// goods
export const mGoodsRoute = { path: `${masterRoute.path}/goods`, name: "common.goods" };
export const mGoodsBarcodeRoute = { path: `${mGoodsRoute.path}/barcode`, name: "master_goods.barcode" };
export const mGoodsCreateRoute = { path: `${mGoodsRoute.path}/create`, name: `master_goods.add` };
export const mGoodsEditRoute = { path: `${mGoodsRoute.path}/edit`, name: "master_goods.edit" };
export const mGoodsPriceRoute = { path: `${mGoodsRoute.path}/price`, name: "master_goods.price" };
export const mGoodsUploadRoute = { path: `${mGoodsRoute.path}/upload`, name: "master_goods.upload" };
export const mGoodsViewRoute = { path: `${mGoodsRoute.path}/view`, name: "master_goods.view" };

// grouping
export const mGroupingRoute = { path: `${masterRoute.path}/grouping`, name: "Pengelompokan Barang" };
export const mGroupingCreateRoute = { path: `${mGroupingRoute.path}/create`, name: "master.groupingadd" };
export const mGroupingEditRoute = { path: `${mGroupingRoute.path}/edit`, name: "master.groupingedit" };

// profile
export const mProfileRoute = { path: `${masterRoute.path}/profile`, name: "common.profile" };
export const mProfileCreateRoute = { path: `${mProfileRoute.path}/create`, name: "master.profileadd" };
export const mProfileEditRoute = { path: `${mProfileRoute.path}/edit`, name: "master.profileedit" };

//process
export const mProcessRoute = { path: `${masterRoute.path}/production`, name: "master.process." };
export const mProcessCreateRoute = { path: `${mProcessRoute.path}/add`, name: "master.process.add" };
export const mProcessEditRoute = { path: `${mProcessRoute.path}/edit`, name: "master.process.edit" };
// end route /////////////////////

// component
const Master = lazy(() => import("../page/master/Master"));
const MUser = lazy(() => import("../page/master/user/MasterUser"));
const MUserForm = lazy(() => import("../page/master/user/MasterUserForm"));
const MUnitCategory = lazy(() => import("../page/master/unit_category/MasterUnitCategory"));
const MSupplierCustomer = lazy(() => import("../page/master/supplier_customer/MasterSupplierCustomer"));
const MGoods = lazy(() => import("../page/master/goods/MasterGoods"));
const MGoodsView = lazy(() => import("../page/master/goods/detail/MasterGoodsView"));
const MGoodsForm = lazy(() => import("../page/master/goods/form/MasterGoodsForm"));
const MGoodsUploadForm = lazy(() => import("../page/master/goods/MasterGoodsUploadForm"));
const MGoodsPriceForm = lazy(() => import("../page/master/goods/price/MasterGoodsPrice"));
const MGoodsBarcodeRoute = lazy(() => import("../page/master/goods/barcode/MasterGoodsBarcode"));
const MProfile = lazy(() => import("../page/master/profile/MasterProfile"));
const MProfileForm = lazy(() => import("../page/master/profile/MasterProfileForm"));
const MGrouping = lazy(() => import("../page/master/grouping/MasterGrouping"));
const MGroupingForm = lazy(() => import("../page/master/grouping/MasterGroupingForm"));
const MConversion = lazy(() => import("../page/master/conversion/MasterConversion"));
const MTNCSetting = lazy(() => import("../page/master/tnc_setting/MasterTNCSetting"));
const MStorageRoom = lazy(() => import("../page/master/storage_room/MasterStorageRoom"));
const MFinanceRoom = lazy(() => import("../page/master/finance/MasterFinance"));
const MBankAccount = lazy(() => import("../page/master/bank_account/MasterBankAccount"));
const MPaymentMethod = lazy(() => import("../page/master/payment_method/MasterPaymentMethod"));
const MProcess = lazy(() => import("../page/master/process/MasterProcess"));
const MProcessForm = lazy(() => import("../page/master/process/form/MasterProcessForm"));
// const MPOFee = lazy(() => import("../page/master/po_fee/POFee"));
const MWarehouse = lazy(() => import("../page/master/warehouse/MasterWarehouse"));
const MWarehouseAddressForm = lazy(() => import("../page/master/warehouse/MasterWarehouseAddressForm"));
const MCourier = lazy(() => import("../page/master/courier/MasterCourier"))
const MSales = lazy(() => import("../page/master/sales/MasterSales"))
// end component

export const masterListRoutes = [
    mUserRoute,
    mCourierRoute,
    mSalesRoute,
    mCustomerRoute,
    mSupplierRoute,
    mGoodsRoute,
    mProfileRoute,
    // mGroupingRoute,
    mConvertionRoute,
    mTNCRoute,
    // mStorageRoomRoute,
    mBankAccRoute,
    mPaymentMethodRoute,
    mProcessRoute,
    // mPOFeeRoute,
    mFinanceRoute,
    mWarehouseRoute,
    mUnitRoute,
    mCategoryRoute,
];

export const masterMutationRoute = [
    mGoodsCreateRoute, mGoodsEditRoute, mGoodsUploadRoute, mGoodsPriceRoute, mGoodsBarcodeRoute, mGoodsViewRoute,
    mGroupingCreateRoute, mGroupingEditRoute,
    mProfileCreateRoute, mProfileEditRoute,
    mProcessCreateRoute, mProcessEditRoute,
    mUserCreateRoute, mUserEditRoute,
    mWarehouseAddressCreateRoute, mWarehouseAddressEditRoute
]

export const masterRoutes = {
    element: <Master />,
    children: [
        { ...mUserRoute, element: <MUser /> },
        { ...mCourierRoute, element: <MCourier /> },
        { ...mSalesRoute, element: <MSales /> },
        { ...mUserCreateRoute, element: <MUserForm type="add" /> },
        { ...mUserEditRoute, element: <MUserForm type="edit" /> },
        { ...mUnitRoute, element: <MUnitCategory type="unit" /> },
        { ...mCategoryRoute, element: <MUnitCategory type="category" /> },
        { ...mGoodsRoute, element: <MGoods /> },
        { ...mGoodsViewRoute, element: <MGoodsView /> },
        { ...mGoodsCreateRoute, element: <MGoodsForm type="add" /> },
        { ...mGoodsEditRoute, element: <MGoodsForm type="edit" /> },
        { ...mGoodsUploadRoute, element: <MGoodsUploadForm /> },
        { ...mGoodsPriceRoute, element: <MGoodsPriceForm /> },
        { ...mGoodsBarcodeRoute, element: <MGoodsBarcodeRoute /> },
        { ...mCustomerRoute, element: <MSupplierCustomer type="customer" /> },
        { ...mSupplierRoute, element: <MSupplierCustomer type="supplier" /> },
        { ...mProfileRoute, element: <MProfile /> },
        { ...mProfileCreateRoute, element: <MProfileForm type="add" /> },
        { ...mProfileEditRoute, element: <MProfileForm type="edit" /> },
        { ...mGroupingRoute, element: <MGrouping /> },
        { ...mGroupingCreateRoute, element: <MGroupingForm type="add" /> },
        { ...mGroupingEditRoute, element: <MGroupingForm type="edit" /> },
        { ...mConvertionRoute, element: <MConversion /> },
        { ...mTNCRoute, element: <MTNCSetting /> },
        { ...mStorageRoomRoute, element: <MStorageRoom /> },
        { ...mBankAccRoute, element: <MBankAccount /> },
        { ...mPaymentMethodRoute, element: <MPaymentMethod /> },
        { ...mProcessRoute, element: <MProcess /> },
        { ...mProcessCreateRoute, element: <MProcessForm type="add" /> },
        { ...mProcessEditRoute, element: <MProcessForm type="edit" /> },
        // { ...mPOFeeRoute, element: <MPOFee /> },
        { ...mFinanceRoute, element: <MFinanceRoom /> },
        { ...mWarehouseRoute, element: <MWarehouse /> },
        { ...mWarehouseAddressCreateRoute, element: <MWarehouseAddressForm type='add' /> },
        { ...mWarehouseAddressEditRoute, element: <MWarehouseAddressForm type='edit' /> },
    ],
};